<template>
  <Portlet
    :title="$t('notesView.notes')"
    icon="history"
  >
    <div class="historyComments">
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(historyComments)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(historyComments)"
        :sortable="{
          allowUnsort: true,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="nameLink"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <router-link
              :to="`/installation/${ props.dataItem.installationId }`"
              class="alt-primary-color"
              target="_blank"
            >
              <span>{{ kgm_getNestedValue(props.field, props.dataItem) }}</span>
            </router-link>
          </td>
        </template>
        <template
          slot="preTemplate"
          slot-scope="{props}"
        >
          <td
            :class="props.className"
            v-html="kgm_getNestedValue(props.field, props.dataItem)"
          />
        </template>
        <template
          slot="dateTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            {{ dateTime_dateTime(props.dataItem.lastCreateDate) }}
          </td>
        </template>

        <div
          slot="nameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="nameFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('nameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="lastUserNameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="userFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a user name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('userFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="lastCreateDateFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="lastCreateDateInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a date"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('lastCreateDateInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="lastCreateDateFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="lastCreateDateInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a date"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('lastCreateDateInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="amoutOfDayFromLastMessageFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="amoutOfDayFromLastMessageInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a number"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('amoutOfDayFromLastMessageInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="amoutOfSevenDaysMessagesFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="amoutOfSevenDaysMessagesInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a number"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('amoutOfSevenDaysMessagesInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group">
              <button
                class="btn btn-primary btn-sm"
                @click="openNotesModal(props.dataItem)"
              >
                <span class="hide-down-sm">{{ $t('notesView.moreNotes') }}</span>
                <span class="hide-up-sm">All</span>
              </button>
              <button
                class="btn btn-success btn-sm"
                @click="addNote(props.dataItem.installationId)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="plus"
                />
                <span class="hide-down-sm">Create</span>
              </button>
            </div>
          </td>
        </template>
      </Grid>
      <SweetModal
        ref="moreNotes"
        :title="$t('notesView.notes')"
        blocking
        class="overflowHidden"
      >
        <template v-if="selectedNote">
          <div
            style="overflow: auto; max-height: 500px;"
          >
            <table class="table">
              <thead>
                <tr>
                  <th
                    scope="col"
                    class="hide-down-sm"
                  >
                    Type
                  </th>
                  <th scope="col">
                    Date
                  </th>
                  <th
                    scope="col"
                  >
                    Recipient
                  </th>
                  <th
                    scope="col"
                    class="hide-down-sm"
                  >
                    From
                  </th>
                  <th scope="col">
                    Note
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in selectedNote.lastComments"
                  :key="`id-listItem-${ index }`"
                >
                  <td class="hide-down-sm">
                    {{ item.type }}
                  </td>
                  <td>
                    {{ dateTime_dateTime(item.created) }}
                  </td>
                  <td>
                    {{ item.recipient }}
                  </td>
                  <td class="hide-down-sm">
                    {{ item.userName }}
                  </td>
                  <td>
                    <span v-html="changeMessage(item.message)" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
        <button
          slot="button"
          class="btn btn-secondary float-right mb-3"
          @click="$refs.moreNotes.close()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="times"
          />{{ $t('cancel') }}
        </button>
        <div class="clearfix" />
      </SweetModal>
    </div>
  </Portlet>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { SweetModal } from 'sweet-modal-vue';

export default {
  name: 'HistoryComments',
  components: {
    SweetModal
  },
  mixins: [
    dateTimeMixin,
    kendoGridMixin
  ],
  props: {
    historyComments: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selectedNote: null,
      kgm_columns: [
        {
          field: 'installationName',
          filterable: true,
          filter: 'text',
          title: 'Name',
          cell: 'nameLink',
          filterCell: "nameFilter"
        },
        {
          field: 'lastCreateDate',
          filterable: true,
          filter: 'text',
          title: this.$t('notesView.noteCreateDate'),
          filterCell: "lastCreateDateFilter",
          cell: 'dateTemplate'
        },
        {
          field: 'lastUserName',
          filterable: true,
          filter: 'text',
          title: this.$t('notesView.lastUser'),
          hideOn: ['smDown'],
          filterCell: "lastUserNameFilter"
        },
        {
          field: 'amoutOfDayFromLastMessage',
          filterable: true,
          filter: 'text',
          title: this.$t('notesView.amoutLastDayMessage'),
          hideOn: ['xlDown'],
          filterCell: "amoutOfDayFromLastMessageFilter"
        },
        {
          field: 'amoutOfSevenDaysMessages',
          filterable: true,
          filter: 'text',
          title: this.$t('notesView.amoutSevenDayMessage'),
          hideOn: ['xlDown'],
          filterCell: "amoutOfSevenDaysMessagesFilter"
        },
        {
          filterable: false,
          cell: 'optionsTemplate',
        }
      ]
    }
  },
  methods: {
    changeMessage (message) {
       return message ? message.replace(/\n/g, "<br>").replaceAll(";", "<br>") : ''
    },
    openNotesModal (note) {
      this.selectedNote = note;
      this.$refs.moreNotes.open();
    },
    addNote (id) {
      this.$emit("showNoteAdd", id);
    }
  }
}
</script>
<style>
.historyComments .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>
